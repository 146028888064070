import React, {useState, useEffect} from "react";
import moment from "moment";
import { renderButtons, renderVersions } from "../Helpers";
import { NO_KEY } from "../../../../helpers/constants/keys";
import classnames from "classnames";
import JSZip from 'jszip';
function TrackContant({
    styles,
    genre,
    bpm,
    musicKey,
    released,
    track,
    presetName,
    activeLetter,
    superShortScrollbarRef,
    index,
    isBillboard = false,
    size,
    mood
}) {
    const displayKey = musicKey === NO_KEY ? "" : musicKey;
    let isRemixeTrack = false;
    let isThrowbacks = false;
    let isIntroOutro =false;
    let isIndie =false;
    let isChristmas =false;
    if (size === "superShort") {
        if (track && track.versions[0] && track.versions[0].presetId) {
            if (track.versions[0].presetId === 9 && track.versions[0].genreName && track.versions[0].genreName.toLowerCase() !== 'remixes') {
                isRemixeTrack = true;
            }
            if (track.versions[0].presetId === 8 && track.versions[0].genreName && track.versions[0].genreName.toLowerCase() !== 'throwbacks') {
                isThrowbacks = true;
            }
            if (track.versions[0].presetId === 17) {
                isIndie = true;
            }
            if (track.versions[0].presetId === 22) {
                isChristmas = true;
            }
            
            if (track.versions[0].presetId === 14) {
                isIntroOutro = true;
            }
        }
    } else {
        if (track.presetId) {
            if (track.presetId === 9 && track.genre && track.genre.toLowerCase() !== 'remixes') {
                isRemixeTrack = true;
            }
            if (track.presetId === 8 && track.genre && track.genre.toLowerCase() !== 'throwbacks') {
                isThrowbacks = true;
            }
            if (track.presetId === 17) {
                isIndie = true;
            }
            if (track.presetId === 14) {
                isIntroOutro = true;
            }
            if (track.presetId === 22) {
                isChristmas = true;
            }
        }
    }
    const [isDownloading, setIsDownloading] = useState(false);
    const [trackUrls, setTrackUrls] = useState([]);
    useEffect(() => {
        const collectTrackUrls = async () => {
            if (track && track.versions && track.versions.length > 0 && !trackUrls.length) {
                if (track.versions.length > 0) {
                    const urls = await Promise.all(track.versions.map(elm => elm.link));
                    setTrackUrls(urls);
                }
            }
        }
        collectTrackUrls();
    }, [track, trackUrls]);
    
    
    const handleDownload = async (track, trackUrls) => {
        if(isDownloading === true){
          return;
        }
        setIsDownloading(true);
        
        // const trackUrls = [
        //     'http://localhost:3010/January 16th ft 16th - Upload Speed Test (Intro Clean).mp3',
        //     'http://localhost:3010/Malie Donn - V6 (Snip Hitz Dirty).mp3',
        //     'http://localhost:3010/Selena Gomez - Love On (Snip Hitz Dirty).mp3'];
        const zip = new JSZip();
        //console.log(track, trackUrls, 'track-----------track');
        try {
          const downloadPromises = trackUrls.map(async (url, index) => {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            const fileName = decodeURIComponent(url).split('/').pop();
            zip.file(fileName, buffer);
          });
    
          await Promise.all(downloadPromises);
    
          const content = await zip.generateAsync({ type: 'blob' });
    
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = track.artist+' - '+track.name+'.zip';
          link.click();
          setIsDownloading(false);
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
    if ((presetName && presetName.toLowerCase === 'promo albums') || size === "superShort") {
        return (
            <>
                <div className={styles.genre}>
                    {(isThrowbacks) && (<>Throwbacks<br/></>)}
                    {(isRemixeTrack) && (<>Remixes<br/></>)}
                    {(isIntroOutro) && (<>Intro Outro<br/></>)}
                    {(isIndie) && (<>Indie<br/></>)}
                    {isChristmas && <>Christmas </>}
                    {genre}
                </div>
                <div className={styles.bpm}>{bpm}</div>
                <div className={styles.key}>{displayKey}</div>
                <div className={styles.released}>
                    {moment(released).format("MM-DD-YYYY")}
                </div>
                {renderButtons({
                    className: styles.buttons,
                    size,
                    track,
                    activeLetter,
                    superShortScrollbarRef,
                    index
                })}
                {renderVersions({
                    size,
                    track,
                    styles,
                    className: styles.vers,
                    presetName,
                    isBillboard
                })}
            </>
        );
    } else {
        return (
            <>
                <div className={classnames(styles.rightcolumn)}>
                    <div className={styles.trackinfonew}>
                    {size === "longVersion" ? 
                        renderButtons({
                            className: styles.buttons,
                            size,
                            track,
                            activeLetter,
                            superShortScrollbarRef,
                            index
                        })
                     : <>
                        <div className={styles.bpmandkeyinfo}>
                            <div>BPM <b className={styles.bpmtxt}>{bpm ? bpm : "--"}</b></div>
                            <span>/</span>
                            <div>Key <b className={styles.keytxt}>{displayKey ? displayKey : "--"}</b></div>
                        </div>
                        </>}
                        {/* <div className={styles.moodinfo}>
                            {size === "superShort" ? (
                                track.versions[0] && track.versions[0].mood ? (
                                    <>Mood <b className={styles.moodtxt}>{track.versions[0].mood}</b></>
                                ) : (
                                    <>Mood <b className={styles.moodtxt}>{mood ? mood : "--"}</b></>
                                )
                            ) : size === "longVersion" ? (
                                // Condition for long version where genre is not shown
                                null
                            ) : (
                                <>Mood <b className={styles.moodtxt}>{mood ? mood : "--"}</b></>
                            )}
                        </div> */}
                        {size === "longVersion" ? <></> : <>
                        <div className={styles.genreinfo}>
                            {isThrowbacks && <>Throwbacks </>}
                            {isRemixeTrack && <>Remixes </>}
                            {isIntroOutro && <>Intro Outro </>}
                            {isIndie && <>Indie </>}
                            {isChristmas && <>Christmas </>}
                            {
                                size === "superShort" ? (
                                    track.versions[0] && track.versions[0].genreName ? (
                                        <b className={styles.genretxt}>{track.versions[0].genreName}</b>
                                    ) : (
                                        genre ? <b className={styles.genretxt}>{genre}</b> : <>Genre <b className={styles.genretxt}>--</b></>
                                    )
                                ) : (
                                    genre ? <b className={styles.genretxt}>{genre}</b> : <>Genre <b className={styles.genretxt}>--</b></>
                                )
                            }   
                        </div>
                        </>}
                    </div>
                    <div className={classnames({
                    [styles.dateOrbtn]: true,
                    [styles.longVersion]: size === 'longVersion'
                })}>
                        <div className={styles.date}>
                            {moment(released).format("MM-DD-YYYY")}
                        </div>
                        {size === "long" && trackUrls && trackUrls.length > 0 && <button
                            className={styles.downloadAll}
                            onClick={() => handleDownload(track, trackUrls)}
                            >
                            {(isDownloading === true) ? 'Downloading...' : 'Download All'}
                            </button>
                        }
                    </div>
                </div>
                {renderVersions({
                    size,
                    track,
                    styles,
                    className: styles.vers,
                    presetName,
                    isBillboard
                })}
            </>
        );
    }
}

export default TrackContant;
