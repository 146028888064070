import React, { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import classNames from 'classnames';
import styles from './AIAssistant.module.scss';
import personImage from '../../assets/images/white-person.svg';
import { PlayButton } from "../common/ControlElems";
import ReactDOMServer from 'react-dom/server';
import { TracksList } from '../common/TracksList';

// Category icons mapping
const CATEGORY_ICONS = {
    booking: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5v-5z" />
        </svg>
    ),
    social: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
        </svg>
    ),
    design: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
        </svg>
    ),
    finance: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
        </svg>
    ),
    release: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z" />
        </svg>
    ),
    gear: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" />
        </svg>
    ),
    music: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6zm-2 16c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
        </svg>
    ),
    setlist: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" />
        </svg>
    ),
    legal: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13 7.5h5v2h-5zm0 7h5v2h-5zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM11 6H6v5h5V6zm-1 4H7V7h3v3zm1 3H6v5h5v-5zm-1 4H7v-3h3v3z" />
        </svg>
    ),
    network: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
        </svg>
    )
};

const AIAssistant = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const { user } = useContext(AuthContext);
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch('/api/ai/categories', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // Removing auth header since we don't want authentication
                }
            });

            if (response.status === 401) {
                // Handle unauthorized case gracefully
                setCategories([
                    {
                        id: 7,
                        title: "Music",
                        description: "Get music recommendations and playlists",
                        category_id: "music",
                        agent_name: "Music Curator"
                    },
                    // Add other categories as needed
                ]);
                setIsCategoriesLoading(false);
                return;
            }

            const result = await response.json();

            if (result.success) {
                setCategories(result.data);
            } else {
                setError('Failed to load categories');
            }
        } catch (err) {
            setError('Error connecting to the server');
            console.error('Error fetching categories:', err);
        } finally {
            setIsCategoriesLoading(false);
        }
    };

    const handleDownloadImage = async (imageUrl) => {
        try {
            setIsLoading(true);
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = imageUrl;  // Use the URL directly
            link.target = '_blank'; // Open in new tab
            link.download = `ai-generated-image-${Date.now()}.png`; // Suggested filename

            // Programmatically click the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.error('Error downloading image:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!inputMessage.trim() && !selectedImage) return;

        const newMessage = {
            role: 'user',
            content: inputMessage,
            image: selectedImage,
            timestamp: new Date().toISOString(),
            category: selectedCategory
        };

        setMessages(prev => [...prev, newMessage]);
        setInputMessage('');
        setSelectedImage(null);
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append('message', inputMessage);
            formData.append('categoryId', selectedCategory.id);
            formData.append('user_id', user.id);

            if (selectedImage) {
                const imageFile = await fetch(selectedImage).then(r => r.blob());
                formData.append('image', imageFile);
            }

            const response = await fetch('/api/ai/chat', {
                method: 'POST',
                body: formData
            });

            const result = await response.json();
            console.log('AI Response:', result);

            if (result.success) {
                console.log('in success');
                const aiResponse = {
                    role: 'assistant',
                    timestamp: new Date().toISOString(),
                    category: selectedCategory
                };

                // Handle different response types
                if (result.uniqueTrackIds) {
                    // Music response handling
                    const trackIdsList = result.uniqueTrackIds.map(id => id.toString());
                    aiResponse.music = trackIdsList;
                    if (result.curatorInsights) {
                        aiResponse.content = result.curatorInsights;
                    }
                } else if (result.data && result.data.type === 'image') {
                    // Image response handling
                    aiResponse.content = result.data.prompt || 'Here is your generated image:';
                    aiResponse.image = result.data.url;
                } else if (result.data) {
                    // Regular chat response handling
                    const content = typeof result.data === 'string' 
                        ? result.data
                        : result.data.content || result.data.response || JSON.stringify(result.data);
                    
                    // Remove any <think> tags and their content if present
                    aiResponse.content = content.replace(/<think>[\s\S]*?<\/think>/g, '').trim();
                }

                // Add agent name prefix to content if it exists
                if (aiResponse.content) {
                    aiResponse.content = `[${selectedCategory.agent_name}]: ${aiResponse.content}`;
                }

                setMessages(prev => [...prev, aiResponse]);
            } else {
                throw new Error(result.message || 'Failed to get AI response');
            }
        }
        catch (err) {
            const errorMessage = {
                role: 'assistant',
                content: `[System]: Sorry, there was an error processing your request. Please try again.`,
                timestamp: new Date().toISOString(),
                category: selectedCategory,
                isError: true
            };
            setMessages(prev => [...prev, errorMessage]);
            console.error('Error sending message:', err);
        } finally {
            setIsLoading(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setMessages([]); // Clear messages when switching categories
    };

    if (error) {
        return (
            <div className={styles.error}>
                <h2>Error</h2>
                <p>{error}</p>
                <button onClick={fetchCategories} className={styles.retryButton}>
                    Retry
                </button>
            </div>
        );
    }

    return (
        <div className={`${styles.aiAssistant} ${styles.aiAssistant_advanced}`}>
            <div className={styles.sidebar}>
                <div className={styles.sidebarTitlecontent}>
                    {/* <img src={personImage} alt="white-person" /> */}
                    <h2>AI Agent</h2>
                </div>
                <div className={styles.categoryList}>
                    {isCategoriesLoading ? (
                        <div className={styles.loading}>Loading categories...</div>
                    ) : (
                        categories.map((category) => (
                            <button
                                title={category.description}
                                key={category.id}
                                className={classNames(styles.categoryButton, {
                                    [styles.selected]: selectedCategory && selectedCategory.id === category.id
                                })}
                                onClick={() => handleCategorySelect(category)}
                            >
                                <div className={styles.categoryTitle}>
                                    {CATEGORY_ICONS[category.category_id]}
                                </div>
                                <div className={styles.categoryTitleContent}>
                                    <span>{category.title}</span>
                                </div>
                                {/* <div className={styles.categoryDescription}>{category.description}</div> */}

                            </button>
                        ))
                    )}
                </div>
            </div>

            <div className={styles.mainContent}>
                <div className={`${styles.chatContainer} ${styles.chatContainer_advanced}`}>
                    <div className={styles.messagesContainer}>
                        {messages.length === 0 ? (
                            <div className={styles.welcome}>
                                <h1>FRP AI Assistant</h1>
                                <p>{selectedCategory ? selectedCategory.description : 'Select a category to get started'}</p>
                                {selectedCategory && (
                                    <div className={styles.agentName}>
                                        Your assistant: {selectedCategory.agent_name}
                                    </div>
                                )}
                            </div>
                        ) : (
                            messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={classNames(styles.message, {
                                        [styles.userMessage]: message.role === 'user',
                                        [styles.aiMessage]: message.role === 'assistant',
                                        [styles.errorMessage]: message.isError
                                    })}
                                >
                                    <div className={styles.messageContent}>
                                        {message.image && (
                                            <div className={styles.imageContainer}>
                                                <img
                                                    src={message.image}
                                                    alt="Uploaded content"
                                                    className={styles.uploadedImage}
                                                />
                                                {message.role === 'assistant' && (
                                                    <button
                                                        onClick={() => handleDownloadImage(message.image)}
                                                        className={styles.downloadButton}
                                                        title="Download Image"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                            <path d="M12 16l-5-5h3V4h4v7h3l-5 5zm-5 4h10v-2H7v2z" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                        {message.isHtml ? (
                                            <div dangerouslySetInnerHTML={{ __html: message.content }} />
                                        ) : (
                                            message.content
                                        )}
                                        {message.platform && (
                                            <div className={styles.platform}>
                                                Platform: {message.platform}
                                            </div>
                                        )}
                                        {message.music && (
                                            <div className={styles.tracksContainer}>
                                                <TracksList trackIds={message.music} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                        {isLoading && (
                            <div className={styles.loading}>
                                AI is thinking...
                            </div>
                        )}
                    </div>

                    <form onSubmit={handleSendMessage} className={styles.inputContainer}>
                        {selectedImage && (
                            <div className={styles.imagePreviewContainer}>
                                <img
                                    src={selectedImage}
                                    alt="Preview"
                                    className={styles.imagePreview}
                                />
                                <button
                                    type="button"
                                    onClick={handleRemoveImage}
                                    className={styles.removeImageButton}
                                >
                                    ×
                                </button>
                            </div>
                        )}
                        <div className={styles.inputWrapper}>
                            <input
                                type="text"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                placeholder={selectedCategory ? `Ask ${selectedCategory.agent_name}...` : "Select a category to start..."}
                                className={styles.input}
                                disabled={!selectedCategory}
                            />
                            <label className={styles.uploadButton}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className={styles.fileInput}
                                    ref={fileInputRef}
                                    disabled={!selectedCategory}
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={styles.uploadIcon}>
                                    <path d="M12 5.5l-5 5h3v5h4v-5z" />
                                    <path d="M4 19h16v-7h-2v5H6v-5H4v7z" />
                                </svg>
                            </label>
                            <button
                                type="submit"
                                className={styles.sendButton}
                                disabled={(!inputMessage.trim() && !selectedImage) || isLoading || !selectedCategory}
                            >
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AIAssistant; 